import React from "react";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

function ThankYouPage() {
  return (
    <Layout>
      <Seo
        title="Danke"
      />
      <section className="mx-auto pt-40 pb-12 px-4 container lg:px-24 xl:px-32 min-h-screen duration-500" data-sal="slide-down" data-sal-delay="300">
        <div>
          <h1 className="text-secondary pb-2">Vielen Dank</h1>
          <div className="h1 pb-12"><span className="text-gray-800">Perfekt, die Nachricht wurde verschickt</span>.</div>
        </div>
        <div className="ml-auto mr-0 max-w-md article">
          <p>Sobald die Brieftaube landet werden wir uns Ihrer Nachricht annehmen. Bei dringenden Fragen können Sie uns auch gerne unter <a href="tel:+41816500030">+41 (0)81 650 00 30</a> erreichen.</p>
        </div>
      </section>

    </Layout>
  );
}

export default ThankYouPage;